<template>
  <div :style="componentStyle">
    <h4 style="text-align: center">
      High Latency Warning: You may experience degraded system performance
    </h4>
  </div>
</template>

<script>
export default {
  name: 'LatencyWarning',
  data() {
    return {
      componentStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        padding: '10px',
        zIndex: 1000,
        background: 'rgba(255, 0, 27, 0.5)'
      }
    }
  }
}
</script>
